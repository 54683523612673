<template>
  <div style="width:100%;float:left;">
    <div class="col-6 m-auto pt-2">
      <CCard class="p-4">
        <CCardBody>
          <CAlert
            :show.sync="showMessage"
            color="primary"
            fade
          >
            {{ message }}
          </CAlert>
          <CForm @submit.prevent="login" method="POST">
            <h1>Login</h1>
            <p class="text-muted">Sign In to your account</p>
            <CInput
              v-model="email"
              prependHtml="<i class='cui-user'></i>"
              placeholder="Email"
              autocomplete="username email"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
            <CInput
              v-model="password"
              prependHtml="<i class='cui-lock-locked'></i>"
              placeholder="Password"
              type="password"
              autocomplete="curent-password"
            >
              <template #prepend-content><CIcon name="cil-lock-locked"/></template>
            </CInput>
            <CRow>
              <CCol col="6" class="text-left">
                <CButton type="submit" color="success" class="px-4">Login</CButton>
              </CCol>
              <CCol col="6" class="text-right">
                <CButton color="link" class="px-0" @click="$router.push('/forgot_password')">Forgot password?</CButton>
                <!-- <CButton color="link" class="d-md-none" @click="goRegister()">Register now!</CButton> -->
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>

import axios from "axios";

    export default {
      name: 'Login',
      data() {
        return {
          email: '',
          password: '',
          showMessage: false,
          message: '',
          dismissCountDown: 5
        }
      },
      mounted: function () {
        console.log('mounted router')
        console.log(this.$route)
      },
      methods: {
        goRegister(){
          this.$router.push({ path: 'register' });
        },
        login() {
          let self = this;
          console.log('login')
          axios.post( this.$apiAdress + '/api/login', {
            email: self.email,
            password: self.password,
          }).then(function (response) {
            console.log(response)
            // self.email = '';
            self.password = '';
            localStorage.setItem("api_token", response.data.access_token);
            localStorage.setItem('roles', response.data.roles);
            self.$store.commit('fetchUser')
            console.log('router')
            console.log(self.$router)
            // self.$router.push({ path: 'dashboard' });
            if (self.$route.params.nextUrl) {
              self.$router.push({path: self.$route.params.nextUrl})
            } else {
              self.$router.push('/dashboard')
            }
          })
          .catch(function (error) {
            self.message = 'Incorrect E-mail or password';
            self.showMessage = true;
            console.log(error);
          });
  
        }
      }
    }

</script>
